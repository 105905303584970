<template>
  <div class="zq-card-row">
    <div class="zq-card-row--label">
      <div class="overview-label">{{ this.getLabel(label,fieldName) }}:</div>
    </div>
    <div class="zq-card-row--content">
      <span
        v-if="contentCopy && !html && this.isNotMapOrArray() && fieldName !== 'source' && fieldName !== 'status' && fieldName !== 'lastKnownStatus' && !isIcon.includes(fieldName)"
        class="zq-card-row--content__text"
        ref="contentRef"
        :class="{ 'zq-card-row--content__bold': bold }"
      >
        <router-link v-if="fieldName === 'entityId' && entityTypePreview" :to="entityTypePreview">
          {{ prepareContent(contentCopy) }}
        </router-link>
        <router-link v-else-if="fieldName === 'rewardId'" :to="{name: 'PreviewReward', params: { id: prepareContent(contentCopy) } }">
          {{ prepareContent(contentCopy) }}
        </router-link>
        <router-link v-else-if="fieldName === 'memberId'" :to="{name: 'PreviewMember', params: { id: prepareContent(contentCopy) } }">
          {{ prepareContent(contentCopy) }}
        </router-link>
        <span v-else>
          {{ prepareContent(contentCopy) }}
        </span>
      </span>

      <div v-if="this.parentClass === 'Transformer' && fieldName === 'source'">
        <codemirror ref="myCm" :options="cmOptions" v-model="contentCopy" />
      </div>

      <div
        v-if="fieldName === 'status'"
        :class="['status-box', `status-box__${contentCopy}`]"
      >
        {{ contentCopy }}
      </div>

      <div
        v-if="fieldName === 'lastKnownStatus'"
        :class="['status-box', `status-box__${contentCopy}`]"
      >
        {{ contentCopy }}
      </div>

      <span
        v-if="contentCopy && html"
        class="zq-card-row--content__text"
        :class="{ 'zq-card-row--content__bold': bold }"
        v-html="contentCopy"
      ></span>

      <div v-if="isIcon.includes(fieldName)">
        <img :src="iconUrl"  alt="file" height="60px" v-if="iconUrl"/>
      </div>

      <ul class="zq--list" v-if="contentCopy && !html && this.isArray()">
        <li v-for="(group, index) in contentCopy" :key="index" class="wrap_list">
          <div class="wrap_item_group mt-1" v-if="fieldName === 'products'">
            <div class="item_group group__colored">{{ group.name }}</div>
          </div>
          <div class="wrap_item_group mt-1" v-else>
            <div class="item_group group__colored">{{ group }}</div>
          </div>
        </li>
      </ul>
<!--      <ul class="zq&#45;&#45;list" v-if="content && !html && this.isArray()">
        <li v-for="(group, index) in content" :key="index" class="wrap_list">
         <div v-for="(key, index) in group" :key="index + 1" class="wrap_item_group mt-1" >
           <div class="item_group group__colored">{{ index }}</div>
           <div class="item_group group__colored ml-1">{{ key }}</div>
         </div>
        </li>
      </ul>-->

      <ul v-if="contentCopy && !html && this.isMap()">
        <li v-for="(item, index) in Object.keys(contentCopy)" :key="index">
          <span>{{ item }} &nbsp;:</span>&nbsp;
          <span>{{ contentCopy[item] }}</span>
          <br />
        </li>
      </ul>

      <slot name="content"></slot>
      <slot />
      <i
        v-if="fieldName === 'id' || fieldName === 'entityId' || fieldName === 'rewardId'"
        class="fa fa-copy zq-card-row--copy-icon"
        @click="handleCopy"
      ></i>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from '@/utils/textUtils';
import { dateFormate } from '@/utils/dateFormate';
import { mapActions } from "vuex";

/**
 * fieldConstraints:
 * minimum, maximum, exclusiveMinimum, exclusiveMaximum, required, deprecated
 * hasMoreNonReadOnly, isPrimitiveType, isModel, isContainer, isString, isNumeric,
 * isInteger, isShort, isLong, isUnboundedInteger, isNumber, isFloat, isDouble,
 * isDecimal, isByteArray, isBinary, isFile, isBoolean, isDate, isDateTime, isUuid,
 * isUri, isEmail, isFreeFormObject, isArray, isMap, isEnum, isReadOnly, isWriteOnly,
 * isNullable, isSelfReference, isCircularReference, isDiscriminator
 *
 */
export default {
  name: 'ZqCardRow',
  props: {
    label: String,
    fieldName: String,
    parentClass: String,
    html: Boolean,
    bold: {
      type: Boolean,
      default: false
    },
    fieldConstraints: [],
    content: {},
    entityTypePreview: Object
  },
  data() {
    return {
      constraints: this.fieldConstraints,
      contentCopy: null,
      iconUrl: null,
      cmOptions: {
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: true,
        lineWrapping: true,
        hintOptions:{
          completeSingle: false
        },
        readOnly: 'nocursor'
      },
      isIcon: ['icon', 'banner', 'bannerLowResolution', 'bannerHighResolution']
    };
  },
  async created() {
    if (this.fieldName === 'lastKnownStatus' && !this.content) {
      this.contentCopy = 'Stopped';
    } else {
      this.contentCopy = this.content;
    }

    if (this.parentClass === 'Webhook' && this.fieldName === 'triggers') {
      this.getWebhookTriggers();
    }

    if (this.isIcon.includes(this.fieldName)) {
      await this.getIcon(this.contentCopy);
    }
  },
  methods: {
    ...mapActions('webhooks', ['handleGetWebhooksTriggers']),
    ...mapActions('files', ['handleGetFileObjects_item']),
    handleCopy() {
      copyToClipboard(this.$refs.contentRef);
    },
    getLabel(userDefined, systemDefined){
      if(userDefined) return userDefined;
      else return systemDefined;
    },
    dateFormate,
    prepareContent(rawContent) {
      if(rawContent) {
        if (this.isDateTime() || this.isDate())
          return this.dateFormate(rawContent)
        else
          return rawContent
      }
      else {
        return rawContent
      }
    },
    async getWebhookTriggers() {
      const triggersList = await this.handleGetWebhooksTriggers();

      this.contentCopy = triggersList
          .filter(item => this.content.includes(item.id))
          .map(item => item.name);
    },
    async getIcon(id) {
      const file = await this.handleGetFileObjects_item({idArray: [id]});
      if (Array.isArray(file) && file.length && file[0].uri) {
        this.iconUrl = file[0].uri;
      }
    },
    isMapOrArray() { return this.isArray() || this.isMap(); },
    isNotMapOrArray() { return !this.isMapOrArray(); },
    deprecated() { return this.constraints.includes('deprecated'); },
    hasMoreNonReadOnly() { return this.constraints.includes('hasMoreNonReadOnly'); },
    isPrimitiveType() { return this.constraints.includes('isPrimitiveType'); },
    isModel() { return this.constraints.includes('isModel'); },
    isContainer() { return this.constraints.includes('isContainer'); },
    isString() { return this.constraints.includes('isString'); },
    isNumeric() { return this.constraints.includes('isNumeric'); },
    isInteger() { return this.constraints.includes('isInteger'); },
    isShort() { return this.constraints.includes('isShort'); },
    isLong() { return this.constraints.includes('isLong'); },
    isUnboundedInteger() { return this.constraints.includes('isUnboundedInteger'); },
    isNumber() { return this.constraints.includes('isNumber'); },
    isFloat() { return this.constraints.includes('isFloat'); },
    isDouble() { return this.constraints.includes('isDouble'); },
    isDecimal() { return this.constraints.includes('isDecimal'); },
    isByteArray() { return this.constraints.includes('isByteArray'); },
    isBinary() { return this.constraints.includes('isBinary'); },
    isFile() { return this.constraints.includes('isFile'); },
    isBoolean() { return this.constraints.includes('isBoolean'); },
    isDate() { return this.constraints.includes('isDate'); },
    isDateTime() { return this.constraints.includes('isDateTime'); },
    isUuid() { return this.constraints.includes('isUuid'); },
    isUri() { return this.constraints.includes('isUri'); },
    isEmail() { return this.constraints.includes('isEmail'); },
    isFreeFormObject() { return this.constraints.includes('isFreeFormObject'); },
    isArray() { return this.constraints.includes('isArray'); },
    isMap() { return this.constraints.includes('isMap'); },
    isEnum() { return this.constraints.includes('isEnum'); },
    isReadOnly() { return this.constraints.includes('isReadOnly'); },
    isWriteOnly() { return this.constraints.includes('isWriteOnly'); },
    isNullable() { return this.constraints.includes('isNullable'); },
    isSelfReference() { return this.constraints.includes('isSelfReference'); },
    isCircularReference() { return this.constraints.includes('isCircularReference'); },
    isDiscriminator() { return this.constraints.includes('isDiscriminator'); },
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

.zq-card-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  min-height: 32px;

  .zq-card-row--content {
    font-size: 13px;
    color: #282f37;
    min-width: 60px;
    max-width: 600px;
    flex: 1;
    margin-bottom: 0.5rem;
    word-break: break-word;
    margin-left: 0.5rem;
    overflow: hidden;
    //.zq-card-row--content__text.zq-card-row--content__bold {
    //  font-weight: bold;
    //}
    .status-box {
      &__Active {
        background-color: #bfe8ee;
      }
      &__Ready {
        background-color: #d7f1d1;
      }
      &__Finished {
        background-color: #fcedce;
      }
      &__Cancelled {
        background-color: #f9d2cd;
      }
      &__Deleted {
        background-color: #f9d2cd;
      }
      &__Draft {
        background-color: #cccccc;
      }
      &__Stopped {
        background-color: #f9d2cd;
      }
      &__Started {
        background-color: #bfe8ee;
      }
    }

    @include media-breakpoint-up("1200px") {
      max-width: 900px;
    }
  }

  .zq-card-row--copy-icon {
    margin-left: 0.5rem;
    cursor: copy;
  }
}

.zq-card-row--label {
  min-width: 186px;
  max-width: 220px;
  width: 100%;
  .overview-label {
    margin-bottom: 0.5rem;
    font-style: italic;
    color: darkgray;
  }
}
</style>